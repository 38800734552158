<template>
  <div>
    <button
      class="uk-button uk-button-default uk-button-large uk-width-1-1"
      type="button"
      :style="'background-color: ' + localValue"
    >
      <span v-if="!localValue">Farbe wählen</span>
      <span v-else>&nbsp;</span>
    </button>
    <div uk-dropdown="mode: click;delay-hide: 0" class="uk-padding-remove uk-width-medium" ref="colorpicker">
      <div class="colorpicker">
        <chrome-picker :value="colors" @input="updateFromPicker" />
      </div>
      <div class="uk-padding-small">
        <p class="uk-text-right uk-margin-remove-bottom">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-margin-small-right uk-border-rounded" @click.prevent="cancel">Abrechen</button>
          <button type="button" class="uk-button uk-button-primary uk-button-small uk-border-rounded" @click.prevent="choose">Wählen</button>
        </p>
      </div>
    </div>
    <div v-if="localValue">
      <a href="#" @click.prevent="setColor(null)">zurücksetzen</a>
    </div>
  </div>
</template>
<script>
import { Chrome } from "vue-color";
export default {
  components: {
    "chrome-picker": Chrome
  },
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      colors: {
        hex: "#000000"
      },
      localValue: ""
    };
  },
  watch: {
    //
  },
  mounted() {
    setTimeout(() => {
      this.setColor(this.value || "#000000");
    }, 300)
  },
  destroyed() {
    this.hidePicker();
  },
  methods: {
    setColor(color) {
      this.updateColors(color);
      this.localValue = color;
    },
    updateColors(val) {
      this.colors = val;
    },
    showPicker() {
      document.addEventListener("click", this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener("click", this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.localValue);
    },
    choose() {
      this.$emit("input", this.localValue);
      this.close();
    },
    cancel() {
      this.setColor(this.value);
      this.close();
    },
    close() {
      this.$uikit.dropdown(this.$refs.colorpicker).hide();
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a === 1) {
        this.localValue = color.hex;
      } else {
        // eslint-disable-next-line
        this.localValue =
          "rgba(" +
          color.rgba.r +
          ", " +
          color.rgba.g +
          ", " +
          color.rgba.b +
          ", " +
          color.rgba.a +
          ")";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.vc-chrome {
  width: 100%;
  box-shadow: none;
}
</style>
