<template>
  <div class="uk-width-1-1">
    <div class="uk-background-default uk-padding-small">
      <form class="uk-form-stacked">
        <div class="uk-grid" uk-grid>
          <div class="uk-width-1-1">
            <div class="uk-margin">
              <label class="uk-form-label">Name</label>
              <input type="text" class="uk-input" v-model="form.name" />
            </div>
            <div class="uk-margin">
              <label class="uk-form-label">ID</label>
              <input type="text" class="uk-input" v-model="form.css_id" />
              <div class="uk-text-muted uk-text-small">
                Eindeutige CSS ID für diesen Bereich.
              </div>
            </div>
          </div>
        </div>
        <div class="uk-grid uk-child-width-1-3@m" uk-grid>
          <div>
            <div class="uk-margin">
              <label class="uk-form-label">Stil</label>
              <select class="uk-select" v-model="form.style">
                <option value="default">Standart</option>
                <option value="primary">Primär</option>
                <option value="secondary">Sekundär</option>
                <option value="muted">Muted</option>
              </select>
            </div>
          </div>
          <div>
            <div class="uk-margin">
              <label class="uk-form-label">Maximale Breite</label>
              <select class="uk-select" v-model="form.width">
                <option value>Standart</option>
                <option value="xsmall">Sehr klein</option>
                <option value="small">Klein</option>
                <option value="large">Groß</option>
                <option value="expand">Expandiert</option>
                <option value="none">Ohne</option>
              </select>
            </div>
          </div>
          <div>
            <div class="uk-margin">
              <label class="uk-form-label">Innenabstand</label>
              <select class="uk-select" v-model="form.padding">
                <option value>Standart</option>
                <option value="xsmall">Sehr klein</option>
                <option value="small">Klein</option>
                <option value="large">Groß</option>
                <option value="xlarge">Sehr groß</option>
                <option value="none">Ohne</option>
              </select>
              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  :disabled="form.padding === 'none'"
                  v-model="form.padding_remove_top"
                /> Entferne Abstand oben
              </label>
              <br />
              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  :disabled="form.padding === 'none'"
                  v-model="form.padding_remove_bottom"
                /> Entferne Abstand unten
              </label>
            </div>
          </div>
          <div v-if="form.style === 'primary' || form.style === 'secondary'">
            <div class="uk-margin">
              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  v-model="form.preserve_color"
                /> Erhalte Text Farbe
              </label>
            </div>
          </div>
        </div>
        <div class="uk-grid uk-child-width-1-1@m" uk-grid>
          <div>
            <div class="uk-margin">
              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  v-model="form.scrollspy"
                /> Aktiviere Scrollspy
              </label>
              <div class="uk-text-muted uk-text-small">
                Benutze Scrollspy Effekt innerhalb des Bereiches. Die Aktivierung erfolgt in den jeweiligen Elementen durch setzen des 'uk-scrollspy-class' Attributes. Animation kann überschrieben werden.
              </div>
            </div>
          </div>
          <div v-if="form.scrollspy">
            <div class="uk-grid uk-grid-small uk-child-width-1-3@m" data-uk-grid>
              <div>
                <div class="uk-margin">
                  <label class="uk-form-label">Standart Animation</label>
                  <select class="uk-select" v-model="form.scrollspy_animation">
                    <option value="fade">fade</option>
                    <option value="slide-top">slide-top</option>
                    <option value="slide-bottom">slide-bottom</option>
                    <option value="slide-left">slide-left</option>
                    <option value="slide-right">slide-right</option>
                    <option value="scale-up">scale-up</option>
                    <option value="scale-down">scale-down</option>
                    <option value="slide-top-small">slide-top-small</option>
                    <option value="slide-bottom-small">slide-bottom-small</option>
                    <option value="slide-left-small">slide-left-small</option>
                    <option value="slide-right-small">slide-right-small</option>
                    <option value="slide-top-medium">slide-top-medium</option>
                    <option value="slide-bottom-medium">slide-bottom-medium</option>
                    <option value="slide-left-medium">slide-left-medium</option>
                    <option value="slide-right-medium">slide-right-medium</option>
                  </select>
                </div>
              </div>
              <div>
                <div class="uk-margin">
                  <label class="uk-form-label">Verzögerung</label>
                  <input type="text" class="uk-input" v-model="form.scrollspy_delay" />
                  <div class="uk-text-muted uk-text-small">
                    Verzögerung in ms zwischen den Einblendungen
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="uk-grid" data-uk-grid>
          <div class="uk-width-1-1">
            <div class="uk-margin">
              <label class="uk-form-label">Hintergrundbild</label>
              <select-image
                :source="form.image"
                @image-selected="onImageSelected"
                @image-removed="onImageRemoved"
              />
              <input type="text" class="uk-input" placeholder="http://" v-model="form.image" />
            </div>
            <div class="uk-margin">
              <div class="uk-grid uk-child-width-1-2@m">
                <div>
                  <div>
                    <label class="uk-form-label">Breite</label>
                    <input
                      type="text"
                      class="uk-input"
                      v-model="form.image_width"
                      placeholder="auto"
                    />
                  </div>
                </div>
                <div>
                  <label class="uk-form-label">Höhe</label>
                  <input
                    type="text"
                    class="uk-input"
                    v-model="form.image_height"
                    placeholder="auto"
                  />
                </div>
              </div>
              <p class="uk-text-small uk-text-muted uk-margin-small">Erklärungstext hier rein!</p>
            </div>
            <div class="uk-margin">
              <div class="uk-grid uk-child-width-1-2@m">
                <div>
                  <div>
                    <label class="uk-form-label">Bildgröße</label>
                    <select class="uk-select" v-model="form.image_size">
                      <option value>Auto</option>
                      <option value="cover">Cover</option>
                      <option value="contain">Contain</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label class="uk-form-label">Bild Position</label>
                  <select class="uk-select" v-model="form.image_position">
                    <option value="top-left">Oben Links</option>
                    <option value="top-center">Oben Zentriert</option>
                    <option value="top-right">Oben Rechts</option>
                    <option value="center-left">Zentriert Links</option>
                    <option value="center-center">Zentriert Zentriert</option>
                    <option value="center-right">Zentriert Rechts</option>
                    <option value="bottom-left">Unten Links</option>
                    <option value="bottom-center">Unten Zentriert</option>
                    <option value="bottom-right">Unten Rechts</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <div class="uk-grid uk-child-width-1-2@m">
                <div>
                  <div class="uk-margin">
                    <label class="uk-form-label">Effekt</label>
                    <select class="uk-select" v-model="form.image_effect">
                      <option value>Ohne</option>
                      <!--<option value="parallax">Parallax</option>-->
                      <option value="fixed">Fixiert</option>
                    </select>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label">Überlagerung</label>
                    <colorpicker :value="form.image_overlay" v-model="form.image_overlay" />
                  </div>
                </div>
                <div>
                  <label class="uk-form-label">Sichtbarkeit</label>
                  <select class="uk-select" v-model="form.image_visibility">
                    <option value="">Immer</option>
                    <option value="s">Klein (Handy)</option>
                    <option value="m">Medium (Tablet)</option>
                    <option value="l">Groß (Bildschirm)</option>
                    <option value="xl">Sehr groß (Große Bildschirme)</option>
                  </select>
                  <p
                    class="uk-text-small uk-text-muted uk-margin-small"
                  >Bild nur bei dieser Bildschirmgröße und höher anzeigen.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import SelectImage from '@/components/finder/SelectImage'
import Colorpicker from '@/components/Colorpicker'
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    SelectImage,
    Colorpicker
  },
  data () {
    return {
      form: {}
    }
  },
  mounted () {
    this.form = this.data
  },
  methods: {
    onImageSelected (image) {
      this.form.image = image
    },
    onImageRemoved () {
      this.form.image = ''
    }
  }
}
</script>
